<template>
  <div
    class="bg-blue"
    style="height: 100%"
  >
    <div class="signin">
      <div class="has-text-centered">
        <img
          class="mb-4"
          src="/img/logo.svg"
          alt="Nutriweb"
          width="134"
          height="46"
        >
      </div>
      <Card
        v-if="valid"
        content-class="px"
      >
        <ValidationObserver
          ref="observer"
          v-slot="{ handleSubmit }"
          tag="div"
        >
          <form
            method="post"
            autocomplete="off"
            @submit.prevent="handleSubmit(changePassword)"
          >
            <b-input-validation
              v-model="password"
              rules="required|min:6"
              :label="$t('new_password')"
              vid="new_password"
              input-type="password"
              :placeholder="placeholders.user.password"
              class="pb-4"
            />
            <b-input-validation
              v-model="password_confirmation"
              rules="required|confirmed:new_password"
              :label="$t('confirm_password')"
              vid="confirm_password"
              input-type="password"
              :placeholder="placeholders.user.password"
            />
            <b-button
              type="is-primary"
              class="mt-4"
              :disabled="loading"
              :loading="loading"
              tag="input"
              native-type="submit"
              :value="$t('save')"
            >
              {{ $t("save") }}
            </b-button>
          </form>
        </ValidationObserver>
      </Card>
      <Card
        v-else-if="!loadingPage"
        content-class="px has-text-centered"
      >
        <b-icon
          icon="link-variant-remove"
          custom-size="is-small"
        />
        {{ $t('invalid_token_or_email') }}
      </Card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      loadingPage: true,
      valid: false,
      password: null,
      password_confirmation: null,
      message: null
    };
  },
  created(){
    this.$axios.get(`password/reset/${this.$route.params.reset_token}/${this.$route.params.email_token}`).then(()=> {
      this.valid = true;
    }).catch(e => this.clientError(e))
    .finally(()=> {
      this.loadingPage = false;
    })
  },
  methods: {
    changePassword() {
      this.loading = true;
      let user = {
        password: this.password,
        confirmation: this.password_confirmation
      };

      this.$axios
        .patch(`password/reset/${this.$route.params.reset_token}/${this.$route.params.email_token}`, user)
        .then(() => {
          this.notify(this.$t("request_successfully_sent"), "is-success");
          this.$router.push("/signin");
          this.loading = false;
        })
        .catch(e => {
          this.loading = false;
          if (e.response && e.response.data.message) {
            const message = e.response.data.message;
            this.message = message;
          }
        });
    }
  }
};
</script>
